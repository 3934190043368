import * as React from "react";

import Layout from "../components/Layout";

const ContactPage = () => {
  return (
    <Layout seo={{ title: "Contact" }}>
      <div className="p-6 prose dark:prose-dark prose-sm sm:prose lg:prose mx-auto">
        <p>
          Feel free to contact me under{" "}
          <a href="mailto:contact@lyght.haus">contact@lyght.haus</a>
        </p>
      </div>
    </Layout>
  );
};

export default ContactPage;
